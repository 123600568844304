import React from "react"

import Layout from '../../components/layout'
import Head from '../../components/head'
import calcStyles from './calculators.module.scss'

import HedgeCalc from "../../components/Calculators/hedge_calculator"
import CalculatorDrop from "../../components/Title Drop/calculator_drop"



const HedgeCalculator = () => {

    return (
        <Layout>
            <Head
                title="Hedge Bet Calculator"
                pageDescription="A free tool for bettors to determine the optimal hedge bet size"
            />
                <CalculatorDrop
                    startingItem={'Hedge Bet Calculator'}
                />
                <p className={calcStyles.calculator_subtitle}>
                    A free tool for bettors to determine the optimal hedge bet size
                </p>
                <HedgeCalc/>
                <div className={calcStyles.content_container}>
                    <h3 className={calcStyles.content_h3}>
                        Hedging Bets
                    </h3>
                    <p className={calcStyles.content_p}>
                        Let’s say you placed an offseason futures bet on a team to win the Super Bowl and then they go ahead and make it. You stand to win a significant return if they win, but if they don’t, you’ll lose your entire bet amount. To lock-in some of your return, you decide to place a second bet on their opponent to win. That way if your team loses, you’ll still get something back in return. This kind of wager is called a hedge bet. Hedge bets are an effective way to lock-in returns and can be used when two different criteria are met.<br className={calcStyles.content_break}/>
                        First, you need an effective way to bet the opposite of your first bet. A Super Bowl futures bet is easy to hedge because you can bet the opponent to win outright. No matter what happens in the game, either your original bet or your hedge bet will hit. <br className={calcStyles.content_break}/>
                        If there are multiple ways for you to lose your original bet, then it will be much harder to hedge. Let’s say you placed a futures bet on a QB to win the MVP and now you want to hedge. If it’s a two man race, you can just bet the other MVP contender. However, if there are multiple players in contention, your original bet can’t be hedged entirely with one additional wager. For this hedging calculator, we’ll assume you have a clean way to bet the opposite side of your original wager.<br className={calcStyles.content_break}/>
                        The second important criteria for placing a hedge bet is that your original odds have improved. If your original bet is now even less likely to win than when you first made it, there’s no way to fully hedge your risk. To fully offset any losses, you’d need to bet more on the hedge bet than you can actually win on your first bet. There are some cases where you still might want to hedge your original risk, but for this hedge calculator, we’ll assume that your original sport bet odds have improved and you’re looking to lock-in a return.
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        Hedging Strategies
                    </h3>
                    <p className={calcStyles.content_p}>
                        There are actually a few different ways to hedge in sports betting depending on the type of risk you may still want to take. To understand which strategy is right for you, you first need to consider the expected value of your hedge bet. Hedge bets are placed at current odds, and since the sportsbook will still charge some vig (aka juice), that means your hedge bet is likely to have negative expected value. As you hedge more of your original risk, you'll end up paying a bigger potential price to the book. A hedge betting calculator helps determine the total bet amount you need to stake based on your desired strategy.
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        Hedging Strategy 1: Maximize Expected Value
                    </h3>
                    <p className={calcStyles.content_p}>
                        Assuming your hedge bet has negative expected value, the combined expected value of your original bet and your hedge bet is maximized when you don't have any hedge at all! If you select this strategy, the hedge calculator will show the current expected value of your original bet, using the hedge odds entered for the hedge bet to determine the implied probability of your original bet winning. Your expected value is maximized, but no risk is hedged, and you still stand to lose all of your original bet.
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        Hedging Strategy 2: Break-even
                    </h3>
                    <p className={calcStyles.content_p}>
                        Placing a hedge bet that pays just enough to cover any losses on your original bet is called a break-even strategy. With this strategy, you still win more if your original bet hits, but you at least break-even when it loses. If you compare this strategy to the Maximize Expected Value strategy, you’ll see that the Break-Even strategy has a lower expected value. Effectively, you’re trading a little bit of potential return for limited downside.
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        Hedging Strategy 3: Equal Profit
                    </h3>
                    <p className={calcStyles.content_p}>
                        The third hedging strategy removes risk entirely and creates a guaranteed profit. It places a hedge wager that pays more than can be lost on the original bet. If you select this option in the hedging calculator above, you’ll see that no matter which bet wins, you’ll still get the same amount of combined profit. Since both outcomes pay the same amount, all of your risk has been hedged away. However, since this strategy places the biggest hedge bet, it also reduces your expected value by the most.
                    </p>
                </div>
        </Layout>
    )

}

export default HedgeCalculator