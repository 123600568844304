import React from "react"

import calcStyles from '../hedge_calculator.module.scss'

const DisplayHedge = ({ originalOdds, originalSize, hedgeOdds, hedgeStrategy, calcVisible }) => {
    
    // init fields //
    var optimalHedgeSize = '--'
    var expectedValue = '--'
    var originalPct = ''
    var hedgePct = ''
    var originalProfitOriginal = ''
    var originalProfitHedge = ''
    var originalProfitCombined = ''
    var hedgeProfitOriginal = ''
    var hedgeProfitHedge = ''
    var hedgeProfitCombined = ''

    // helper funcs //

    // func for imperceisely handling float-point rounding hell //
    function truncate (num, places) {
        return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
    }

    // will abreviate large numbers //
    function displayNum (num, isVis) {

        const parsedNum = parseFloat(num)

        if (isVis) {
            if (Math.abs(parsedNum) > 99999) {
                return (
                    `$${truncate(parsedNum/1000,0)}k`
                )
            } else if (Math.abs(parsedNum) > 9999) {
                return (
                    `$${truncate(parsedNum/1000,1)}k`
                )
            }
            else if (Math.abs(parsedNum) > 999) {
                return (
                    `$${truncate(parsedNum/1000,2)}k`
                )
            } else if (Math.abs(parsedNum) > 99) {
                return (
                    `$${truncate(parsedNum,1)}`
                )
            } else {
                return (
                    `$${truncate(parsedNum,2)}`
                )
            }
        } else {
            return(
                '--'
            )
        }
    }

    // convert odds to dec //
    const americanToDec = (odds) => {

        const parsedOdds = parseInt(odds)

        if (parsedOdds < 0) {
            return (
                1+(100/(-1 * parsedOdds))
            )
        } else {
            return (
                1 + (parsedOdds / 100)
            )
        }
    }

    const americanToPct = (odds) => {

        const parsedOdds = parseInt(odds)

        if (parsedOdds < 0) {
            return (
                (-1 * parsedOdds) / ((-1 * parsedOdds) + 100)
            )
        } else {
            return (
                100 /  (parsedOdds + 100)
            )
        }
    }

    const betProfit = (betSize, betOdds) => {

        const dec = americanToDec(betOdds)

        return (
            parseFloat(betSize) * (dec - 1)
        )
    }


    // if inputs are good, update vars //
    if (calcVisible) {

        // payout //
        if (hedgeStrategy === 'maxEV') {
            optimalHedgeSize = 0
        } else if (hedgeStrategy ==='equalProfit') {
            optimalHedgeSize = (
                parseFloat(originalSize) * 
                (
                    americanToDec(originalOdds) / 
                    americanToDec(hedgeOdds)
                )
            )
        } else {
            optimalHedgeSize = (
                parseFloat(originalSize) / 
                (americanToDec(hedgeOdds)-1)
            )
        }

        // pcts //
        hedgePct = americanToPct(hedgeOdds) - 0.025
        originalPct = 1 - hedgePct

        // payouts //
        originalProfitOriginal = betProfit(originalSize, originalOdds)
        originalProfitHedge = -1 * optimalHedgeSize
        originalProfitCombined = originalProfitOriginal + originalProfitHedge
        hedgeProfitOriginal = -1 * parseFloat(originalSize)
        hedgeProfitHedge = betProfit(optimalHedgeSize, hedgeOdds)
        hedgeProfitCombined = hedgeProfitOriginal + hedgeProfitHedge
    
        // ev //
        expectedValue = (
            hedgePct * hedgeProfitCombined +
            originalPct * originalProfitCombined
        )
    
    }
 

    return (

        <div className={calcStyles.hedge_panel} data-alignment='right'>
            <div className={calcStyles.output_container}>
                <div className={calcStyles.hedge_size_div}>
                    <div className={calcStyles.ev_div}>
                        <p className={calcStyles.ev_val}>
                            {displayNum(expectedValue, calcVisible)}
                        </p>
                        <p className={calcStyles.ev_text}>
                            Expected Value
                        </p>
                    </div>
                    <h2 className={calcStyles.title}>
                        Optimal Hedge Size
                    </h2>
                    <p className={calcStyles.hedge_size}>
                        {calcVisible ? `$${truncate(optimalHedgeSize,2)}` : '--'}
                    </p>
                </div>
                <div className={calcStyles.payouts_div}>
                    <h2 className={calcStyles.title}>
                        Payout Scenarios
                    </h2>
                    <div className={calcStyles.payout_scenario}>
                        <h3 className={calcStyles.payout_scenario_title}>
                            Original Bet Wins
                        </h3>
                        <div className={calcStyles.payout_component_div}>
                            <p className={calcStyles.payout_component}>
                                {calcVisible ? `${truncate(originalPct * 100,1)}%` : '--'}
                            </p>
                            <p className={calcStyles.payout_component_text}>
                                Implied Probability
                            </p>
                        </div>
                        <div className={calcStyles.payout_component_div}>
                            <p className={calcStyles.payout_component}>
                                {displayNum(originalProfitOriginal, calcVisible)}
                            </p>
                            <p className={calcStyles.payout_component_text}>
                                Original Bet Profit
                            </p>
                        </div>
                        <div className={calcStyles.payout_component_div}>
                            <p className={calcStyles.payout_component}>
                                {displayNum(originalProfitHedge, calcVisible)}
                            </p>
                            <p className={calcStyles.payout_component_text}>
                                Hedge Bet Profit
                            </p>
                        </div>
                        <div className={calcStyles.payout_component_div}>
                            <p className={calcStyles.payout_component}>
                                {displayNum(originalProfitCombined, calcVisible)}
                            </p>
                            <p className={calcStyles.payout_component_text}>
                                Combined Profit
                            </p>
                        </div>
                    </div>
                    <div className={calcStyles.payout_scenario}>
                        <h3 className={calcStyles.payout_scenario_title}>
                            Hedge Bet Wins
                        </h3>
                        <div className={calcStyles.payout_component_div}>
                            <p className={calcStyles.payout_component}>
                                {calcVisible ? `${truncate(hedgePct * 100,1)}%` : '--'}
                            </p>
                            <p className={calcStyles.payout_component_text}>
                                Implied Probability
                            </p>
                        </div>
                        <div className={calcStyles.payout_component_div}>
                            <p className={calcStyles.payout_component}>
                                {displayNum(hedgeProfitOriginal, calcVisible)}
                            </p>
                            <p className={calcStyles.payout_component_text}>
                                Original Bet Profit
                            </p>
                        </div>
                        <div className={calcStyles.payout_component_div}>
                            <p className={calcStyles.payout_component}>
                                {displayNum(hedgeProfitHedge, calcVisible)}
                            </p>
                            <p className={calcStyles.payout_component_text}>
                                Hedge Bet Profit
                            </p>
                        </div>
                        <div className={calcStyles.payout_component_div}>
                            <p className={calcStyles.payout_component}>
                                {displayNum(hedgeProfitCombined, calcVisible)}
                            </p>
                            <p className={calcStyles.payout_component_text}>
                                Combined Profit
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    )

}

export default DisplayHedge