import React, { useState } from "react"
import Select from "react-select"

import calcStyles from './hedge_calculator.module.scss'
import DisplayHedge from "./helpers/display_hedge"

const HedgeCalc = () => {

    // set initial states //
    // inputs //
    const [originalOdds, setOriginalOdds] = useState(300)
    const [originalBetSize, setOriginalBetSize] = useState(100)
    const [hedgeOdds, setHedgeOdds] = useState(-150)
    // dropdown menu //
    const [selectedStrategy, setSelectedStrategy] = useState(
        {'value' : 'equalProfit', 'label' : 'Have equal profit no matter who wins'}
    )
    // outputs visibility toggle //
    const [outputVisible, setOutputVisible] = useState(true)
    // alerts //
    const [originalOddsMessage, setOriginalOddsMessage] = useState("")
    const [betSizeMessage, setBetSizeMessage] = useState("")
    const [hedgeMessage, setHedgeMessage] = useState("")
    const [oddsAlertVisible, setOddsAlertVisible] = useState(false)
    const [sizeAlertVisible, setSizeAlertVisible] = useState(false)
    const [hedgeAlertVisible, setHedgeAlertVisible] = useState(false)

    const dropSelections = [
        {'value' : 'maxEV', 'label' : 'Maximize EV'},
        {'value' : 'breakEven', 'label' : 'Break-even if my original bet loses'},
        {'value' : 'equalProfit', 'label' : 'Have equal profit no matter who wins'},
    ]

    // update text entries when value is wrong //
    // active field gets raw entry text, all others are wiped //
    const updateWithError = (activeEntry, rawEntry) => {
        
        //  original odds //
        if (activeEntry === 'originalOdds') {
            // toggle alerts //
            setOddsAlertVisible(true)
            setSizeAlertVisible(false)
            setHedgeAlertVisible(false)
            // update //
            setOriginalOdds(
                rawEntry
            )
        }

        
        //  original size //
        if (activeEntry === 'originalSize') {
            // control viz //
            setOddsAlertVisible(false)
            setSizeAlertVisible(true)
            setHedgeAlertVisible(false)
            // update // 
            setOriginalBetSize(
                rawEntry
            )
        }

        //  hedge odds //
        if (activeEntry === 'hedgeOdds') {
            // control viz //
            setOddsAlertVisible(false)
            setSizeAlertVisible(false)
            setHedgeAlertVisible(true)
            // update // 
            setHedgeOdds(
                rawEntry
            )
        }

        // set hold //
        setOutputVisible(false)

    }

    // update logic if correctly formatted item was provided //
    // this will only update the 3 user entries. //
    // Entries will be passed as props with calc done in component //
    const updateWithValue = (activeEntry, rawEntry) => {
        
        
        if (activeEntry === 'originalOdds') {
            // always set entry when dealing with controlled state //
            setOriginalOdds(rawEntry)

            // since update is valid, remove error //
            setOriginalOddsMessage('')
            setOddsAlertVisible(false)

            // if others still have error, we can't update //
            if (betSizeMessage !== '' || hedgeMessage !== '') {

                if (hedgeMessage !== '') {
                    setHedgeAlertVisible(true)
                    setSizeAlertVisible(false)
                } else {
                    setHedgeAlertVisible(false)
                    setSizeAlertVisible(true)
                }

            } else {
                
                // ensure warnings are clear //
                setHedgeAlertVisible(false)
                setSizeAlertVisible(false)

                // set output visible //
                setOutputVisible(true)

            }
        
        } else if (activeEntry === 'originalSize') {
            // always set entry when dealing with controlled state //
            setOriginalBetSize(rawEntry)

            // since update is valid, remove error //
            setBetSizeMessage('')
            setSizeAlertVisible(false)

            // if others still have error, we can't update //
            if (originalOddsMessage !== '' || hedgeMessage !== '') {

                if (hedgeMessage !== '') {
                    setHedgeAlertVisible(true)
                    setOddsAlertVisible(false)
                } else {
                    setHedgeAlertVisible(false)
                    setOddsAlertVisible(true)
                }

            } else {
                
                // ensure warnings are clear //
                setHedgeAlertVisible(false)
                setOddsAlertVisible(false)

                // set output visible //
                setOutputVisible(true)

            }
        
        } else if (activeEntry === 'hedgeOdds') {
            // always set entry when dealing with controlled state //
            setHedgeOdds(rawEntry)

            // since update is valid, remove error //
            setHedgeMessage('')
            setHedgeAlertVisible(false)

            // if others still have error, we can't update //
            if (originalOddsMessage !== '' || betSizeMessage !== '') {

                if (betSizeMessage !== '') {
                    setSizeAlertVisible(true)
                    setOddsAlertVisible(false)
                } else {
                    setSizeAlertVisible(false)
                    setOddsAlertVisible(true)
                }

            } else {
                
                // ensure warnings are clear //
                setSizeAlertVisible(false)
                setOddsAlertVisible(false)

                // set output visible //
                setOutputVisible(true)

            }

        }

    }

    // handle the actual field update //

    // original //
    const updateOriginalOdds = event => {

        const odds = event.target.value
        const americanFormatted = parseFloat(odds).toFixed(1)
        
        if (isNaN(americanFormatted) || (americanFormatted < 100 & americanFormatted >-100)) {
            // alert msg if odds not valid //
            setOriginalOddsMessage('American Odds must be greater than 100 or less than -100')
            updateWithError('originalOdds', odds)
        } else if (isNaN(parseFloat(hedgeOdds))) {
            // if hedge odds are blank, we can just update regular odds //
            updateWithValue('originalOdds', odds)
        } else if (americanFormatted < -1 * parseFloat(hedgeOdds)) {
            // if hedgeOdds exist, we need to check that they are better than the original //
            setOriginalOddsMessage(`Original odds can't be hedged if current value is worse than original. Original odds must be > ${-1 * parseInt(hedgeOdds)}`)
            updateWithError('originalOdds', odds)
        } else {
            updateWithValue('originalOdds', odds)
        }

    }

    // hedge //
    const updateHedgeOdds = event => {

        const odds = event.target.value
        const americanFormatted = parseFloat(odds).toFixed(1)
        
        if (isNaN(americanFormatted) || (americanFormatted < 100 & americanFormatted >-100)) {
            // alert msg if odds not valid //
            setHedgeMessage('American Odds must be greater than 100 or less than -100')
            updateWithError('hedgeOdds', odds)
        } else if (isNaN(parseFloat(originalOdds))) {
            // if original odds are blank, we can just update hedge odds //
            updateWithValue('hedgeOdds', odds)
        } else if (americanFormatted < -1 * parseFloat(originalOdds)) {
            // if originalOdds exist, we need to check that they are better than the hedge //
            setOriginalOddsMessage(`Original odds can't be hedged if current value is worse than original. Hedge odds must be < ${-1 * parseInt(originalOdds)}`)
            updateWithError('hedgeOdds', odds)
        } else {
            updateWithValue('hedgeOdds', odds)
        }

    }

    const updateSize = event => {
        
        const risked = event.target.value
        const riskedValue = parseFloat(risked)

        if (risked === '') {
            setBetSizeMessage(`Original bet size can't be blank`)
            updateWithError('originalSize', risked)
        } else if (isNaN(riskedValue) || (riskedValue <= 0)) {
            setBetSizeMessage(`Original bet size must be a number greater than 0`)
            updateWithError('originalSize', risked)
        } else {
            updateWithValue('originalSize', risked)
        }
    }

    return (

        <div className={calcStyles.calc_wrapper}>
            <div className={calcStyles.hedge_container}>
                <div className={calcStyles.hedge_panel} data-alignment='left'>
                    <div className={calcStyles.param_container}>
                        <h2 className={calcStyles.title}>
                            Hedge Parameters
                        </h2>
                        <div className={calcStyles.param_box}>
                            <h3 className={calcStyles.param_title}>
                                Original Bet Odds
                            </h3>
                            <div className={calcStyles.check_input_container}>
                                <div className={calcStyles.check_div} data-active={oddsAlertVisible}>
                                    {(oddsAlertVisible===false) ? '$' : '\u2297' }
                                </div>
                                <input className={calcStyles.input_value}
                                    id={'originalOdds'}
                                    name={'originalOdds'}
                                    type="text"
                                    value={originalOdds}
                                    onChange={updateOriginalOdds}
                                />
                            </div>
                        </div>
                        <div className={calcStyles.param_box}>
                            <h3 className={calcStyles.param_title}>
                                Original Bet Size
                            </h3>
                            <div className={calcStyles.check_input_container}>
                                <div className={calcStyles.check_div} data-active={sizeAlertVisible}>
                                    {(sizeAlertVisible===false) ? '$' : '\u2297' }
                                </div>
                                <input className={calcStyles.input_value}
                                    id={'originalSize'}
                                    name={'originalSize'}
                                    type="text"
                                    value={originalBetSize}
                                    onChange={updateSize}
                                />
                            </div>
                        </div>
                        <div className={calcStyles.param_box}>
                            <h3 className={calcStyles.param_title}>
                                Hedge Bet Odds
                            </h3>
                            <div className={calcStyles.check_input_container}>
                                <div className={calcStyles.check_div} data-active={hedgeAlertVisible}>
                                    {(hedgeAlertVisible===false) ? '$' : '\u2297' }
                                </div>
                                <input className={calcStyles.input_value}
                                    id={'hedgeOdds'}
                                    name={'hedgeOdds'}
                                    type="text"
                                    value={hedgeOdds}
                                    onChange={updateHedgeOdds}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={calcStyles.param_container} data-alignment='bottom'>
                        <h2 className={calcStyles.title}>
                            Hedging Strategy
                        </h2>
                        <div className={calcStyles.strategy_div}>
                            <h3 className={calcStyles.param_title}>
                                I want to...
                            </h3>
                            <div className={calcStyles.drop_div}>
                                <Select
                                    options={dropSelections}
                                    onChange={setSelectedStrategy}
                                    defaultValue={dropSelections[1]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <DisplayHedge
                    originalOdds={originalOdds}
                    originalSize={originalBetSize}
                    hedgeOdds={hedgeOdds}
                    hedgeStrategy={selectedStrategy['value']}
                    calcVisible={outputVisible}
                />
                <div className={calcStyles.alert_container} data-active={oddsAlertVisible}>
                    <p className={calcStyles.alert} data-active={oddsAlertVisible}>
                        {originalOddsMessage}
                    </p>
                </div>
                <div className={calcStyles.alert_container} data-active={sizeAlertVisible}>
                    <p className={calcStyles.alert} data-active={sizeAlertVisible}>
                        {betSizeMessage}
                    </p>
                </div>
                <div className={calcStyles.alert_container} data-active={hedgeAlertVisible}>
                    <p className={calcStyles.alert} data-active={hedgeAlertVisible}>
                        {hedgeMessage}
                    </p>
                </div>
            </div>
        </div>

    )

}

export default HedgeCalc
    